export const ENVIRONMENT = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAl6gE5rZbNwLFkhAYLWIlZQ9C_bHG4Fkw',
    authDomain: 'cda-aether.firebaseapp.com',
    projectId: 'cda-aether',
    storageBucket: 'cda-aether.appspot.com',
    messagingSenderId: '488648154151',
    appId: '1:488648154151:web:c54c8a4cb6a8eb8d68c202',
  },
};
