import { FormField } from '@cda-libs/cda-models';

export const CLIENT_FORM_FIELDS: FormField[] = [
  {
    controlName: 'firstName',
    label: 'First Name',
    placeholder: 'Nombre',
    type: 'text',
    error: 'Debe introducir su nombre',
  },
  {
    controlName: 'lastName',
    label: 'Last Name',
    placeholder: 'Apellido',
    type: 'text',
    error: 'Debe introducir su apellido',
  },
  {
    controlName: 'email',
    label: 'Email',
    placeholder: 'Email',
    type: 'email',
    error: 'Ingresar un correo válido y que no este registrado',
  },
  {
    controlName: 'dateOfBirth',
    label: 'Date of Birth',
    placeholder: 'Fecha de Nacimiento',
    type: 'date',
    error: 'Debe introducir su fecha de nacimiento',
  },
  {
    controlName: 'phoneNumber',
    label: 'Phone Number',
    placeholder: 'Teléfono',
    type: 'tel-national',
    error:
      'Ingresar un teléfono válido y que no este registrado',
  },
];

export const CASHIER_FORM_FIELDS: FormField[] = [
  ...CLIENT_FORM_FIELDS,
  {
    controlName: 'createdAt',
    label: 'Created Date',
    placeholder: 'Fecha de Creación',
    type: 'date',
    error: '',
  },
];
